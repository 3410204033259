import React from 'react'
import Navbarimg from '../assets/BCRUA_LOGO_RECT.jpg'
import { Link } from "react-router-dom"
import { useLocation } from 'react-router-dom';

export default function Navbar() {

    const location = useLocation();

    const { pathname } = location;

    const splitLocation = pathname.split("/");

    return (
        <div>

            <nav className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: "white" }}>
                <div className="container mt-4 mb-2">
                    <a className="navbar-brand" href="/"><img src={Navbarimg}
                        style={{ height:"125px" }} /></a>
                    <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"> <span className="navbar-toggler-icon"></span> </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav">
                            <li className="nav-item"> <Link className="nav-link text-uppercase" id={(splitLocation[1] == "" ? "active" : "")} to="/" >Home</Link></li>
                            <li className="nav-item d-none d-lg-block"><a className="nav-link">/</a></li>
                            <li className="nav-item"> <Link className="nav-link text-uppercase" id={(splitLocation[1] == "ProjectOverview" ? "active" : "")} to="/ProjectOverview">Project Overview</Link></li>
                            <li className="nav-item d-none d-lg-block"><a className="nav-link">/</a></li>
                            <li className="nav-item"> <Link className="nav-link text-uppercase" id={(splitLocation[1] == "Construction" ? "active" : "")} to="/Construction">Construction Updates</Link></li>
                            <li className="nav-item d-none d-lg-block"><a className="nav-link">/</a></li>
                            <li className="nav-item"> <Link className="nav-link text-uppercase" id={(splitLocation[1] == "ProjectTeam" ? "active" : "")} to="/ProjectTeam">Project Team</Link></li>
                            <li className="nav-item d-none d-lg-block"><a className="nav-link">/</a></li>
                            <li className="nav-item"> <Link className="nav-link text-uppercase" id={(splitLocation[1] == "FAQ" ? "active" : "")} to="/FAQ">FAQ</Link></li>
                            <li className="nav-item d-none d-lg-block"><a className="nav-link">/</a></li>
                            <li className="nav-item"> <a className="nav-link text-uppercase" id={(splitLocation[1] == "Contact" ? "active" : "")} href="/Contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </nav>

        </div>
    )
}
