import logo from './logo.svg';
import './App.css';
import { Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/Navbar'
import ProjectOverview from './pages/ProjectOverview';
import ProjectTeam from './pages/ProjectTeam';
import Contact from './pages/Contact';
import Construction from './pages/Construction';
import Footer from './components/Footer';
import FAQ from './pages/FAQ'

function App() {
  return (
    <BrowserRouter>
      <div className='App'>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ProjectOverview" element={<ProjectOverview/>}/>
          <Route path="/Construction" element={<Construction/>}/>
          <Route path="/ProjectTeam" element={<ProjectTeam/>}/>
          <Route path="/Contact" element={<Contact/>}/>
          <Route path="/FAQ" element={<FAQ/>}/>
        </Routes>
        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;
