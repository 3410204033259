import React, { useEffect, useState } from 'react'
import Alert from './Alert';
import N from '../assets/Notifications.json';

export default function Notifications(props) {
    const val = parseInt(props.length);
    const [alerts,setAlerts] = useState([]);

    const initData = function(){
        var alertsData = [];
        for(let i = 0;i<N.length;i++){
            var now = new Date();
            if(new Date(N[i].endDateTime)>=now){
            alertsData.push(
                <Alert key={N[i].key} timeRange={N[i].timeRange} dateRange={N[i].dateRange} innerText={N[i].innerText} title={N[i].title} ></Alert>
            )
            }
        }
        if (!isNaN(val)) {
            alertsData = alertsData.slice(0,val);
        }
        setAlerts(alertsData);
    }

    useEffect(()=>{
        initData();
    },[])

    return (
        <div>
            <hr />
            {alerts}
        </div>
    )
}
