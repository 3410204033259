import React from 'react'
import ConstructionImg from '../assets/RWPS_Lake Travis_Obtained Permission from Owner for External Use.jpg'
import Notifications from '../components/Notifications'
import CarouselImg1 from '../assets/constructionCarousel/02 Pumpstation 2.jpg'
import CarouselImg2 from '../assets/constructionCarousel/03 3-00670.01 2023-08-02 6207.jpg'
import CarouselImg3 from '../assets/constructionCarousel/03 Empty tunnel.jpg'
import CarouselImg4 from '../assets/constructionCarousel/04-1 tunnel clean.jpg'
import CarouselImg5 from '../assets/constructionCarousel/05 PS site gantries.jpg'
import CarouselImg6 from '../assets/constructionCarousel/06 TER site 1.jpg'
import CarouselImg7 from '../assets/constructionCarousel/07 inspection.jpg'
import CarouselImg8 from '../assets/constructionCarousel/08 3-00670.01 2023-07-26 6126.jpg'
import CarouselImg9 from '../assets/constructionCarousel/10 Marine landing site 2.jpg'
import CarouselImg10 from '../assets/constructionCarousel/11 intake barges.jpg'
import CarouselImg11 from '../assets/constructionCarousel/15 3-00670.01 2023-07-27 6132.jpg'
import CarouselImg12 from '../assets/constructionCarousel/15 on barge inspection.jpg'
import CarouselImg13 from '../assets/constructionCarousel/16 SS inspection.jpg'
import CarouselImg14 from '../assets/constructionCarousel/18 Sandy Plant 1.jpg'
import CarouselImg15 from '../assets/constructionCarousel/download1.jpg'
import CarouselImg16 from '../assets/constructionCarousel/download2.jpg'
import CarouselImg17 from '../assets/constructionCarousel/download3.jpg'
import CarouselImg18 from '../assets/constructionCarousel/download4.jpg'
import CarouselImg19 from '../assets/constructionCarousel/download5.jpg'
import CarouselImg20 from '../assets/constructionCarousel/download6.jpg'
import MapSchedule from '../assets/BCRUA Water Project TimelineV4.JPG'
import ThreeMonthUpdate from '../assets/Aug 2024 90R1lsg day.jpg'
import UpcomingWork from '../assets/Upcoming-Work.pdf'

export default function Construction() {
  return (
    <div>
      <main>
        <div id="myCarousel" className="carousel slide pointer-event" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item-s active c0 c1">
              <img src={ConstructionImg} className="d-block w-100" style={{ objectFit: "cover", height: "445px" }} />
              <div className='carousel-caption' style={{ top: "18vh" }}>
                <h1 className='display-3' style={{ textShadow: "2px 2px 3px #000000" }}>Construction Updates</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row my-5'>
            <div className='col'>
              <h4 className='display-5 mb-2 text-start'>Project Schedule</h4>
            </div>
            <div className='col d-flex justify-content-end h-50'>
              <a href={UpcomingWork} target="blank" className='btn btn-primary'>Upcoming Work <i className="bi bi-filetype-pdf ps-2"></i></a>
            </div>

            <img src={MapSchedule} style={{ objectFit: "cover", }} />
          </div>
          <div className='row my-5'>
            <h4 className='display-5 mb-2 text-start'>Notifications</h4>
            <Notifications />
          </div>
          <div className='row my-5 text-start'>
            <h4 className='display-5 mb-4'>Updates</h4>
            <ul className='ms-5'>
              <li><b>Lime Creek Rd:</b> Maintenance of traffic control and erosion control features, disposal haulage from, and pipe deliveries to Pump Station Site during day time hours. At 8315 Lime Creek Rd drilling of a drop shaft to deliver concrete to the Intake tunnel. The drilling of that shaft will commence early January.</li>
              <li><b>Raw Water Pump Station Site:</b> Tunnel excavation for both tunnels to Trails End Road site is complete, de-mobilization of tunnelling equipment will continue for the next months. Installation of the transmission pipe in the transmission tunnel continues. Work for the final concrete liner in the Intake Tunnel is currently being planned and is expected to start early February. Work pertaining to the pipe installation and tunnel liner placements is expected to be performed in multiple shifts around the clock.</li>
              <li><b>Maintenance Building site:</b> Construction of on-site utilities, structural fill and excavation for onsite utilities work continues, mobilization for drilling equipment for the inclined bores is completed, drilling of 2 inclined bores will start early January. Structures work is scheduled to restart soon.</li>
              <li><b>Trails End Road Shaft and Open Cut area:</b> Construction of Trails End Road Shaft is ongoing, with transmission pipe installation continuing through January and early February.</li>
              <li><b>Trails End Road and Sandy Creek WTP:</b> Minor clearing activities for survey and locating work took place at the WTP. A fiberoptics conduit is being installed by HDD method along Trails End Road from Sandy Creek WTP to TER Shaft. </li>
              <li><b>Lake Travis in-lake work area:</b> Installation of the riser pipes in the lake floor and the extension of the Intake pipes is completed. This concludes Phase 1 of the work on the lake. Phase 2 will include the flooding of the tunnel and cavern after all underground work is completed some time later this year. The work then entails removing of bulkheads and mounting of intake screens. Marine equipment is currently being de-mobilized from the marine staging area at Trails End Road. Equipment still present is associated with a different project.</li>
            </ul>
            <div className='h5 text-center'>Maintenance Building Site and Trails End Road Site 90-day Schedule</div>
            <img src={ThreeMonthUpdate} className='img-fluid' alt=''></img>
          </div>
          <div className='row my-5'>
            <h4 className='display-5 mb-4 text-start'>Progress Photos</h4>
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
              <ol className="carousel-indicators">
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="8" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="9" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="10" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="11" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="12" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="13" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="14" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="15" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="16" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="17" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="18" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="19" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="20" type='button'></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={CarouselImg1} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg2} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg3} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg4} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg5} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg6} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg7} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg8} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg9} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg10} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg11} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg12} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg13} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>

                <div className="carousel-item">
                  <img src={CarouselImg14} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg15} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg16} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg17} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg18} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg19} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg20} className="d-block w-100" style={{ objectFit: "cover", height: "600px" }} />
                  <div className='carousel-caption d-none d-md-block'>

                  </div>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
