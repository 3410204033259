import React from 'react'
import ProjOverImg from '../assets/MB_Avg Lake Level_667_Obtained Permission from Owner for External Use. ver2 - Copy.jpg'
import CarouselImg1 from '../assets/Intakes_Avg Lake Level_667_Obtained Permission from Owner for External Use.jpg'
import CarouselImg2 from '../assets/Intakes_Low Lake Level_627.5_Obtained Permission from Owner for External Use.jpg'
import CarouselImg3 from '../assets/MB_Avg Lake Level_667_Obtained Permission from Owner for External Use.jpg'
import CarouselImg5 from '../assets/RWPS_Lake Travis_Obtained Permission from Owner for External Use.jpg'
import CarouselImg6 from '../assets/RWPS_Lime Creek Road_Obtained Permission from Owner for External Use.jpg'
import ProjectMap from '../assets/Map Draft.jpg'


export default function ProjectOverview() {
  return (
    <div>

      <main className='flex-shrink-0 modular min-vh-100'>

        <div id="myCarousel" className="carousel slide pointer-event" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item-s active c0 c1">
              <img src={ProjOverImg} className="d-block w-100" style={{ objectFit: "cover", height: "445px" }} />
              <div className='carousel-caption' style={{ top: "20vh" }}>
                <h1 className='display-3' style={{ textShadow: "2px 3px 3px #000000" }}>Project Overview</h1>
              </div>
            </div>
          </div>
        </div>

        <div className='container mt-5'>
          <div className='row my-3 text-start'>
            <h4 className='display-5 mb-4'>Project Purpose</h4>
            <p>BCRUA is developing the Phase 2 Raw Water Delivery System to provide safe, reliable water to withstand drought and increase capacity to accommodate future regional growth.
            </p>
          </div>

          <div className='row my-3 text-start'>
            <h4 className='display-5 mb-4'>Project Details</h4>
            <p>The project consists of a Raw Water Intake with two lake taps with multi-level intake screens that connect to a gravity Raw Water Intake Tunnel that transports water to a new 145-Million Gallons per Day (MGD) Raw Water Pump Station. The Raw Water Transmission Tunnel and Pipeline will transport the pumped water to the existing Cedar Park, Sandy Creek, and BCRUA water treatment plants. A Maintenance Building will also be constructed to house chemical feed systems to protect the infrastructure from invasive species and provide quality improvements to the raw water prior to pumping. The project is funded by the BCRUA, its customer cities, and in collaboration with the Texas Water Development Board.  </p>
            <p>Check out a video about the project on the Texas Water Development Board’s website here: <a href="https://texaswaternewsroom.org/videos/new_system_will_increase_water_reliability_and_capacity_in_cedar_park,_leander,_and_round_rock.html" alt=""> https://texaswaternewsroom.org/videos/new_system_will_increase_water_reliability_and_capacity_in_cedar_park,_leander,_and_round_rock.html</a></p>
          </div>

          <div className='row my-3 mb-5 text-start'>
            <h4 className='display-5 mb-4'>Project Map</h4>
            <img src={ProjectMap} style={{ objectFit: "cover", height: "500px" }} />
          </div>

          <div className='row my-3 text-start'>
            <h4 className='display-5 mb-4'>Project Images</h4>
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
              <ol className="carousel-indicators">
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" type='button'></li>
                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" type='button'></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={CarouselImg1} className="d-block w-100" style={{ objectFit: "cover", height: "700px" }} />
                  <div className='carousel-caption d-none d-md-block'>
                    <h5>Rendering of upper raw water intakes</h5>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg2} className="d-block w-100" style={{ objectFit: "cover", height: "700px" }} />
                  <div className='carousel-caption d-none d-md-block'>
                    <h5>Rendering of raw water intakes</h5>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src={CarouselImg3} className="d-block w-100" style={{ objectFit: "cover", height: "700px" }} />
                  <div className='carousel-caption d-none d-md-block'>
                    <h5>Rendering of Maintenance Building Site</h5>
                  </div>
                </div>
                <div className='carousel-item'>
                  <img src={CarouselImg5} className="d-block w-100" style={{ objectFit: "cover", height: "700px" }} />
                  <div className='carousel-caption d-none d-md-block text-dark' style={{textShadow: "2px 1px #ffffff"}}>
                    <h5>Rendering of Raw Water Pump Station (Lake Travis side)</h5>
                  </div>
                </div>
                <div className='carousel-item'>
                  <img src={CarouselImg6} className="d-block w-100" style={{ objectFit: "cover", height: "700px" }} />
                  <div className='carousel-caption d-none d-md-block' style={{textShadow: "2px 1px #000000"}}>
                    <h5>Rendering of Raw Water Pump Station (Lime Creek Road side)</h5>
                  </div>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>



        </div>

      </main>

    </div>
  )
}
