import React from 'react'
import CarImg from '../assets/RWPS_Lime Creek Road_Obtained Permission from Owner for External Use.jpg'
import Notifications from '../components/Notifications'
import CedarParkLogo from '../assets/cedar_park_logo_hrz.jpg'
import LeanderLogo from '../assets/LeanderLogo_wide_color_noback_rgb.png'
import RoundRockLogo from '../assets/rr_logo_hrz.jpg'


export default function Home() {
    return (
        <div>

            <main className='flex-shrink-0 modular min-vh-100'>
                <div id="myCarousel" className="carousel slide pointer-event" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item-s active c0 c1">
                            <img src={CarImg} className="d-block w-100" style={{ objectFit: "cover", height: "445px" }} />
                            <div className='carousel-caption' style={{ top: "20vh" }}>
                                <h1 className='display-3' style={{ textShadow: "2px 2px 3px #000000" }}>Phase 2 Raw Water Delivery System</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">

                    <div className='row'>
                        <div className='col-lg-3 col-12 my-5'>
                            <h4 className='display-6'>Notifications</h4>
                            <Notifications length='3' />
                        </div>
                        <div className='col-lg-9 col-12'>
                            <div className='row'>
                                <div className="row text-center my-4">
                                    <h5 className="text-uppercase" style={{ fontWeight: "lighter" }}>A Partnership of:</h5>
                                    <h5 className="text-uppercase fw-bold">Cedar Park • Leander • Round Rock</h5>
                                </div>

                                <div className="row">
                                    <div className="row">
                                        <img className='mx-auto my-1' src={CedarParkLogo} style={{ objectFit: "cover", width:"600px"}} />
                                    </div>
                                    <div className="row">
                                        <img className='mx-auto my-1' src={LeanderLogo} style={{ objectFit: "cover", width:"450px"}} />
                                    </div>
                                    <div className="row">
                                        <img className='mx-auto my-1' src={RoundRockLogo} style={{ objectFit: "cover", width:"350px" }} />
                                    </div>
                                </div>

                                <div className='col-1'></div>
                                <div className='col-lg-10'>
                                    <div className='row text-start mt-5'>
                                        <h4 style={{ fontWeight: "lighter", color: "#000000" }}>About BCRUA</h4>
                                    </div>
                                    <div className='row text-start my-2'>
                                        <p>The Brushy Creek Regional Utility Authority (BCRUA) is a partnership of the cities of <a style={{ color: "#b3b2b2" }} href="https://www.cedarparktexas.gov" target="_blank">Cedar Park</a>, <a style={{ color: "#b3b2b2" }} href="https://www.leandertx.gov" target="_blank">Leander</a>, and <a style={{ color: "#b3b2b2" }} href="http://www.roundrocktexas.gov" target="_blank">Round Rock</a> to treat and distribute water from the <a style={{ color: "#b3b2b2" }} href="https://www.lcra.org/Pages/default.aspx" target="_blank">Lower Colorado River Authority</a>. The BCRUA is a regional water supply solution that is cost-efficient and environmentally sound, providing long-term supplies to residents and businesses of the partner cities. Its regional strategy is in line with the policies of the <a style={{ color: "#b3b2b2" }} href="http://www.twdb.texas.gov" target="_blank">Texas Water Development Board</a> and the Lower Colorado River Authority’s <a style={{ color: "#b3b2b2" }} href="https://www.lcra.org/water/water-supply-planning/water-management-plan-for-lower-colorado-river-basin/Pages/default.aspx" target="_blank">water management plan</a>.</p>
                                    </div>
                                </div>
                                <div className='col-1'></div>
                            </div>
                        </div>
                    </div>
                </div>



            </main>

        </div>
    )
}
