import React from 'react'

export default function Alert(props) {
    const title = props.title;
    const dateRange = props.dateRange;
    const innerText = props.innerText;
    const timeRange = props.timeRange;
    return (
        <div className='row text-start'>
            <h5>{title}</h5>
            <h6><em>{dateRange}</em></h6>
            <h6><em>{timeRange}</em></h6>
            <p>{innerText}</p>
            <hr />
        </div>
    )
    }
