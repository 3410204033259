import React, { useState } from 'react'
import Contactimg from '../assets/MB_Avg Lake Level_667_Obtained Permission from Owner for External Use.jpg'
import axios from 'axios'
import { useNavigate } from 'react-router'
import ReCAPTCHA from "react-google-recaptcha"
export default function Contact() {

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [comment, setComment] = useState('')
  const [phone, setPhone] = useState('')
  const [firstNameAlert, setFirstNameAlert] = useState(false);
  const [lastNameAlert, setLastNameAlert] = useState(false);
  const [emailAlert, setEmailAlert] = useState(false);
  const [commentAlert, setcommentAlert] = useState(false);
  const [recaptchaVerified,setReCaptchaVerified] = useState(false);
  let navigate = useNavigate();

  const emailConfig = {
    To: ['bcruawaterproject@walkerpartners.com'],
    From: 'noreply@freese.com',
    Subject: 'Test Email',
    ApiKey: 'UqjuU1u9HmRoaXvX0GxTm'
  }

  async function onChange(value) {
    try{
      var res = await axios.post("https://clients.freese.com/FNIEmailAPI/api/ReCaptcha",{
        "key": value
      })
      setReCaptchaVerified(res.data.success);
    }
    catch(e){
      setReCaptchaVerified(false);
    }
  }

  const verifyEmailForm = function () {
    var result = true;
    if (firstName == '') {
      setFirstNameAlert(true);
      result = false;
    }
    else {
      setFirstNameAlert(false);
    }
    if (lastName == '') {
      setLastNameAlert(true);
      result = false;
    }
    else {
      setLastNameAlert(false);
    }
    if (email == '') {
      setEmailAlert(true);
      result = false;
    }
    else {
      setEmailAlert(false);
    }
    if (comment == '') {
      setcommentAlert(true);
      result = false;
    }
    else {
      setcommentAlert(false);
    }

    return result;
  }
  const sendEmail = async function () {
    if (verifyEmailForm()) {
      var body = 'Hello, ' + firstName + ' ' + lastName + ' has submitted the following comment: ' + comment + '. Return Email: ' + email + '. Phone Number: ' + phone;
      var res = await axios.post('https://clients.freese.com/FNIEmailAPI/api/Email/SendEmail', {
        To: emailConfig.To,
        From: emailConfig.From,
        Body: body,
        Subject: emailConfig.Subject
      }, {
        headers: {
          XApiKey: emailConfig.ApiKey
        }
      })
      navigate('/');
    }
  }

  return (
    <div>
      <main className='flex-shrink-0 modular min-vh-100'>
        <div id="myCarousel" className="carousel slide pointer-event" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item-s active c0 c1">
              <img src={Contactimg} className="d-block w-100" style={{ objectFit: "cover", height: "445px" }} />
              <div className='carousel-caption' style={{ top: "20vh" }}>
                <h1 className='display-3' style={{ textShadow: "2px 2px 3px #000000" }}>Contact</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-start">
          <div className="row my-4">

            <div className="col-lg-6 my-4">
              <h5>For emergencies, call 911.<br/><br/>For general inquiries, call 512-298-4417<br/>or fill out the Contact Us form.</h5>
            </div>

            <div className="col-lg-6 mt-2">
              <div className="row">
                <h3>Contact Us</h3>
                <p className='mb-4'>Use the form below to submit questions or comments to the project team.</p>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="inputfirstname" className="form-label">Name</label>
                    <input className="form-control" id="inputfirstname" onChange={(e) => { setFirstName(e.target.value) }} />
                    <div id="firstnamehelp" className="form-text">First Name</div>
                    {firstNameAlert && <div className='text-danger'> Please enter a first name</div>}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="inputlastname" className="form-label text-white">Last Name</label>
                    <input className="form-control" id="inputlastname" onChange={(e) => { setLastName(e.target.value) }} />
                    <div id="lastnamehelp" className="form-text">Last Name</div>
                    {lastNameAlert && <div className='text-danger'> Please enter a last name</div>}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='mb-3'>
                    <div className="mb-3">
                      <label htmlFor="inputphone" className="form-label">Phone Number</label>
                      <input className="form-control" id="inputphone" onChange={(e) => { setPhone(e.target.value) }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label htmlFor="inputemail" className="form-label">Email</label>
                    {emailAlert && <div className='text-danger'> Please enter an email</div>}
                    <input className="form-control" id="inputemail" onChange={(e) => { setEmail(e.target.value) }} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <label htmlFor="inputcomment" className="form-label">Comment</label>
                    {commentAlert && <div className='text-danger'> Please enter a comment</div>}
                    <textarea rows="3" className="form-control" style={{ height: "90px" }} id="inputcomment" onChange={(e) => { setComment(e.target.value) }} ></textarea>
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <ReCAPTCHA sitekey='6LccayUhAAAAAJV7HcvhwrtG2e-TkTgOC7-AGd7F' onChange={onChange} />
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <button className={(recaptchaVerified)?("btn btn-outline-primary"):("btn btn-secondary disabled")} onClick={() => { sendEmail() }}>Email Us</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main >

    </div >
  )
}
