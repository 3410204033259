import React from 'react'
import ProjTeamImg from '../assets/MB_Low Lake Level_627.5_Obtained Permission from Owner for External Use.jpg'
import CedarParkLogo from '../assets/cedar_park_logo_hrz.jpg'
import LeanderLogo from '../assets/LeanderLogo_wide_color_noback_rgb.png'
import RoundRockLogo from '../assets/rr_logo_hrz.jpg'
import logoimg from '../assets/logo.png'
import FNILogo from '../assets/FNI Logo - Blue - RGB.png'
import thalleimg from '../assets/thalle sak logo.png'
import schnabelimg from '../assets/SE_Logo_FullColor_RGB_Small.png'
import kfaimg from '../assets/KFA-logo-CMYK-LightWordmark.png'
import walkerimg from '../assets/PrimaryLogo_WalkerPartners_FullColor.png'

export default function ProjectTeam() {
  return (
    <div>
      <main>

        <div id="myCarousel" className="carousel slide pointer-event" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item-s active c0 c1">
              <img src={ProjTeamImg} className="d-block w-100" style={{ objectFit: "cover", height: "445px" }} />
              <div className='carousel-caption' style={{ top: "20vh" }}>
                <h1 className='display-3' style={{ textShadow: "2px 2px 3px #000000" }}>Project Team</h1>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>

          <div className='row my-5 text-start'>
            <h4 className='display-5 mb-2'>About BCRUA</h4>
            <p>The Brushy Creek Regional Utility Authority (BCRUA) is a partnership of the cities of <a style={{ color: "#b3b2b2" }} href="https://www.cedarparktexas.gov" target="_blank">Cedar Park</a>, <a style={{ color: "#b3b2b2" }} href="https://www.leandertx.gov" target="_blank">Leander</a>, and <a style={{ color: "#b3b2b2" }} href="http://www.roundrocktexas.gov" target="_blank">Round Rock</a> to treat and distribute water from the <a style={{ color: "#b3b2b2" }} href="https://www.lcra.org/Pages/default.aspx" target="_blank">Lower Colorado River Authority</a>. The BCRUA is a regional water supply solution that is cost-efficient and environmentally sound, providing long-term supplies to residents and businesses of the partner cities. Its regional strategy is in line with the policies of the <a style={{ color: "#b3b2b2" }} href="http://www.twdb.texas.gov" target="_blank">Texas Water Development Board</a> and the Lower Colorado River Authority’s <a style={{ color: "#b3b2b2" }} href="https://www.lcra.org/water/water-supply-planning/water-management-plan-for-lower-colorado-river-basin/Pages/default.aspx" target="_blank">water management plan</a>.</p>
            <p>View the <a style={{ color: "#b3b2b2" }} href="https://www.bcrua.org/" target="_blank">BCRUA site</a>.</p>
          </div>

          <div className='row my-5'>
            <h4 className='display-5 mb-2'>Partner Cities</h4>
            <div className="row text-center my-4">
              <h5 className="text-uppercase fw-bold">Cedar Park • Leander • Round Rock</h5>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <a href="https://www.cedarparktexas.gov"> <img className='mx-auto my-1' src={CedarParkLogo} style={{ objectFit: "cover", maxHeight: "120px", maxWidth:"98%" }} /></a>
              </div>
              <div className="col-lg-4">
                <a href="https://www.leandertx.gov"> <img className='mx-auto my-1' src={LeanderLogo} style={{ objectFit: "cover", maxHeight: "140px", maxWidth:"98%" }} /></a>
              </div>
              <div className="col-lg-4">
                <a href="http://www.roundrocktexas.gov"><img className='mx-auto my-1' src={RoundRockLogo} style={{ objectFit: "cover", maxHeight: "160px", maxWidth:"98%" }} /></a>
              </div>
            </div>

          </div>
          <div className='row my-5'>
            <h4 className='display-5 mb-2'>Contractor</h4>
            <div className="row text-center my-4">
              <h5 className="text-uppercase fw-bold">Thalle and SAK Joint Venture</h5>
            </div>
            <div className="row">
              <div className="col-12">
                <a href="#"> <img className='mx-auto my-1' src={thalleimg} style={{ objectFit: "cover", maxHeight: "120px", maxWidth:"98%" }} /></a>
              </div>
            </div>
          </div>
          <div className='row my-5'>
            <h4 className='display-5 mb-2'>Engineering Team</h4>
            <div className="row text-center my-4">
              <h5 className="text-uppercase fw-bold">Walker Partners and Freese and Nichols Joint Venture • K Friese + Associates • Schnabel</h5>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <a href="#"> <img className='mx-auto my-2' src={walkerimg} style={{ objectFit: "cover", maxHeight: "70px", maxWidth:"98%" }} /></a>
              </div>
              <div className="col-lg-3">
                <a href="#"> <img className='mx-auto my-2' src={FNILogo} style={{ objectFit: "cover", maxHeight: "60px", maxWidth:"98%" }} /></a>
              </div>
              <div className="col-lg-3">
                <a href="#"><img className='mx-auto my-2' src={kfaimg} style={{ objectFit: "cover", maxHeight: "80px", maxWidth:"98%" }} /></a>
              </div>
              <div className="col-lg-3">
                <a href="#"> <img className='mx-auto my-2' src={schnabelimg} style={{ objectFit: "cover", maxHeight: "70px", maxWidth:"98%" }} /></a>
              </div>
            </div>
          </div>
        </div>
      </main>


    </div>
  )
}
