import React, { useEffect, useState } from 'react'
import FAQimg from '../assets/Intakes_Low Lake Level_627.5_Obtained Permission from Owner for External Use.jpg'
import FAQs from '../assets/FAQs.json'
import FAQComp from '../components/FAQComp';

export default function FAQ() {
    const [FAQComps, setFAQComps] = useState([]);
    const initData = function(){
        var data = FAQs.map((d)=>{
            return (<FAQComp  question = {d.Question} answer = {d.Answer} key={d.ID}/>)
        });
        setFAQComps(data);
    }

    useEffect(()=>{
        initData();
    },[])

    return (
        <div>
            <main className='flex-shrink-0 modular min-vh-100'>
                <div id="myCarousel" className="carousel slide pointer-event" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item-s active c0 c1">
                            <img src={FAQimg} className="d-block w-100" style={{ objectFit: "cover", height: "445px" }} />
                            <div className='carousel-caption' style={{ top: "20vh" }}>
                                <h1 className='display-3' style={{ textShadow: "2px 2px 3px #000000" }}>Frequently Asked Questions</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container text-start">
                    <div className="row my-4">
                        {FAQComps}
                    </div>
                </div>
            </main >
        </div >
    )
}
