import React from 'react'
import parse from "html-react-parser";
import Link from 'react-router-dom';

export default function FAQComp(props) {
    const question = props.question;
    const answer = props.answer;
    return (
        <div className='mt-3'>
            <div className='h4'>
                {question}
            </div>
            <div>
                {parse(answer)}
            </div>
        </div>
    )
}
