import React from 'react'

export default function Footer() {
    return (
        <div className='pt-5'>
            <footer className="container border-top my-5 pt-5">
                <p className="text-center fw-light"> Terms of Service</p>
                <p className="text-center">Freese and Nichols Inc ©2022</p>
            </footer>
        </div>
    )
}
